var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "park-activity-dialog" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentState === "review",
                  expression: "currentState === 'review'",
                },
              ],
              attrs: { gutter: 48 },
            },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "回顾活动名",
                        prop: "reviewActivityName",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "tagListRef",
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            remote: "",
                            clearable: "",
                            "reserve-keyword": "",
                            placeholder: "请输入活动名称",
                            "remote-method": _vm.getParkActivityHandler,
                            loading: _vm.reviewLoading,
                          },
                          on: { change: _vm.reviewListChange },
                          model: {
                            value: _vm.reviewValue,
                            callback: function ($$v) {
                              _vm.reviewValue = $$v
                            },
                            expression: "reviewValue",
                          },
                        },
                        _vm._l(_vm.reviewOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.reviewValue ? true : false,
                      expression: "reviewValue ? true : false",
                    },
                  ],
                  attrs: { span: 12 },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "覆盖活动预告",
                        prop: "coverFlag",
                        rules: (_vm.reviewValue !== "" ||
                          _vm.reviewValue !== null) && [
                          {
                            required: true,
                            message: "请选择是否覆盖活动预告",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "review-radio-container" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticStyle: { display: "flex" },
                              attrs: { border: "" },
                              model: {
                                value: _vm.ruleForm.coverFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "coverFlag", $$v)
                                },
                                expression: "ruleForm.coverFlag",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { label: "0", border: "" } },
                                [_vm._v("否")]
                              ),
                              _c(
                                "el-radio",
                                { attrs: { label: "1", border: "" } },
                                [_vm._v("是")]
                              ),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "review-tips" }, [
                            _vm._v("(覆盖后小程序将不再展示该活动预告)"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动标题", prop: "activityTitle" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.currentState === "view",
                          maxlength: "50",
                        },
                        model: {
                          value: _vm.ruleForm.activityTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "activityTitle", $$v)
                          },
                          expression: "ruleForm.activityTitle",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发布时间", prop: "pubdate" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.currentState === "view",
                          type: "datetime",
                          "value-format": "timestamp",
                          placeholder: "选择发布时间",
                          "picker-options": _vm.pickerOptions,
                        },
                        model: {
                          value: _vm.ruleForm.pubdate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "pubdate", $$v)
                          },
                          expression: "ruleForm.pubdate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动类型", prop: "activityType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { disabled: _vm.currentState === "view" },
                          model: {
                            value: _vm.ruleForm.activityType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "activityType", $$v)
                            },
                            expression: "ruleForm.activityType",
                          },
                        },
                        _vm._l(_vm.activityTypeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "展示位置", prop: "showAddress" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { disabled: _vm.currentState === "view" },
                          model: {
                            value: _vm.ruleForm.showAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "showAddress", $$v)
                            },
                            expression: "ruleForm.showAddress",
                          },
                        },
                        _vm._l(_vm.showAddressList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { value: item.value, label: item.label },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开始时间", prop: "startTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.currentState === "view",
                          type: "datetime",
                          "value-format": "timestamp",
                          placeholder: "选择开始时间",
                        },
                        model: {
                          value: _vm.ruleForm.startTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "startTime", $$v)
                          },
                          expression: "ruleForm.startTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "结束时间", prop: "endTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          disabled: _vm.currentState === "view",
                          type: "datetime",
                          "value-format": "timestamp",
                          placeholder: "选择结束时间",
                        },
                        model: {
                          value: _vm.ruleForm.endTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "endTime", $$v)
                          },
                          expression: "ruleForm.endTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动地址", prop: "activityAddress" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: _vm.currentState === "view" },
                        model: {
                          value: _vm.ruleForm.activityAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "activityAddress", $$v)
                          },
                          expression: "ruleForm.activityAddress",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发布人", prop: "publisher" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.ruleForm.publisher,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "publisher", $$v)
                          },
                          expression: "ruleForm.publisher",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                {
                  staticClass: "auto-height-item",
                  attrs: { label: "封面图片", prop: "photoIdListId" },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.uploadLoading,
                          expression: "uploadLoading",
                        },
                      ],
                      staticClass: "upload-container",
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            disabled: _vm.currentState === "view",
                            action: _vm.uploadPath,
                            "with-credentials": true,
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess,
                            "before-upload": _vm.beforeAvatarUpload,
                          },
                        },
                        [
                          _vm.imageUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.imageUrl },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handlePictureCardPreview.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                      _c("div", { staticClass: "tips-container" }, [
                        _c("div", [
                          _c("div", [
                            _vm._v(
                              "\n                温馨提示：最多上传1张，图片大小不能超过2m，图片格式为jpg，png。\n              "
                            ),
                          ]),
                          _c("div", [_vm._v("图片尺寸212*112")]),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.currentState !== "view" &&
                                  _vm.ruleForm.photoIdListId.length > 0,
                                expression:
                                  "\n                currentState !== 'view' && ruleForm.photoIdListId.length > 0\n              ",
                              },
                            ],
                            staticClass: "delete-icon",
                            on: { click: _vm.deleteHandler },
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "链接地址", prop: "linkAddress" } },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.currentState === "view" },
                    model: {
                      value: _vm.ruleForm.linkAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "linkAddress", $$v)
                      },
                      expression: "ruleForm.linkAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.currentState !== "view"
            ? _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm()
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.currentState === "view"
            ? _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.changeStateToEdit },
                    },
                    [_vm._v("编辑")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, modal: false },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }